@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.pageLayout {
  height: 100vh;
  background-color: #FDFCF8;
}

.headerTextLayout {
  width: 100%;
  height: 100px;
  flex-shrink: 0;
  margin-top: 30px;
  margin-left: auto; /* Center the button horizontally by setting margin-left and margin-right to auto */
  margin-right: auto; /* Center the button horizontally by setting margin-left and margin-right to auto */
  text-align: center; /* Left-justify the text */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the button vertically */
}


.headerTextText {
  color: var(--Black, #111827);
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.52px;
}

.contentText {
  position: relative;
  z-index: 1;
  background-color: #FDFCF8;
  color: #5C5C5C;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  width: 400px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.contentText p {
  margin-left: 15px;
  margin-right: 50px;
}

.statsText {
  position: relative;
  z-index: 1;
  color: #5C5C5C;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  width: 330px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.statsText p {
  margin-bottom: 10px;
}

.footerRules {
  width: 330px;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heroImage {
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  margin-bottom: 50px;
}

.heroImage img {
  width: 55%;
  height: auto;
  max-width: 250px !important;
}

@media (max-width: 250px) {
  .heroImage img {
    width: 100%;
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  width: 350px;
  max-width: 350px;
}

.card {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  width: 330px;
}

.card p {
  padding: 1px;
}

.card-header {
  background-color: #f7f7f7;
  padding: 10px 15px;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
}

.card-body {
  padding: 15px;
}

.card-body div {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}




.button:hover, .goButton:hover {
  background-color: #d0c7b1;
}

.centered-button {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 20px; /* Adjust as needed */
}
