
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.pageLayout {
    height: 100vh;
}


.headerTextLayout {
  width: 400px;
  height: 100px;
  flex-shrink: 0;
  margin-top: 30px;
  /* Ensure this container is properly positioned */
}

.headerTextText {
  color: var(--Black, #111827);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.52px;
}



.contentText {
position: relative;
z-index: 1;
color: #5C5C5C;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  width: 330px;
  margin: 15px auto; /* Adjusted for auto horizontal margins */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}


.statsText {
  position: relative;
  z-index: 1;
  color: #5C5C5C;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    width: 330px;
    margin: 15px auto; /* Adjusted for auto horizontal margins */
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

.statsText p {
  margin-bottom: 10px; /* Spacing between paragraphs */
}

.footerRules {
    width: 330px;
    margin: 15px auto; /* Adjusted for auto horizontal margins */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heroImage {
  display: flex;
  justify-content: center; /* Center image horizontally */
  margin: 20px 0; /* Add some margin around the image */
}

.heroImage img {
  width: 60%; /* Adjust as needed */
  height: auto;
  /* Add !important to override other styles if absolutely necessary */
  max-width: 250px !important; 
}

@media (max-width: 250px) {
  .heroImage img {
    width: 100%;
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center the cards container */
  padding: 20px; /* Space around the container */
  width: 350px;
  max-width: 350px;
}

.card {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Simple shadow for depth */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures content respects border radius */
  width: 330px; /* Fixed width, adjust as needed */

}

.card p {
  padding: 1px;
}

.card-header {
  background-color: #f7f7f7; /* Light background for the header */
  padding: 10px 15px;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea; /* Subtle separation from the body */
}


.card-body {
  padding: 15px;
}

.card-body div {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0; /* Light background for each offer */
  border-radius: 5px;
}
